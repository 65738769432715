var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accounting-table"
  }, [_c('div', {
    staticClass: "accounting-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "accountingTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "no-local-sorting": true,
      "items": _vm.blindItems,
      "per-page": 10,
      "busy": _vm.isBusy
    },
    scopedSlots: _vm._u([{
      key: "head(selected)",
      fn: function fn() {
        return [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.selectAllRowsBoolean,
            callback: function callback($$v) {
              _vm.selectAllRowsBoolean = $$v;
            },
            expression: "selectAllRowsBoolean"
          }
        })];
      },
      proxy: true
    }, _vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(selected)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table-checkbox",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.toggleTableRow(_vm.bulkRows[rowData.index], rowData.index);
            }
          }
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.bulkRows[rowData.index].value,
            callback: function callback($$v) {
              _vm.$set(_vm.bulkRows[rowData.index], "value", $$v);
            },
            expression: "bulkRows[rowData.index].value"
          }
        })], 1)];
      }
    }, {
      key: "cell(documentNumber)",
      fn: function fn(rowData) {
        return [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: rowData.item.documentNumber,
            expression: "rowData.item.documentNumber",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("trimString")(rowData.item.documentNumber, 30)))])];
      }
    }, {
      key: "cell(fileName)",
      fn: function fn(rowData) {
        return [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: rowData.item.fileName,
            expression: "rowData.item.fileName",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("trimString")(rowData.item.fileName, 10)))])];
      }
    }, {
      key: "cell(counterPartyName)",
      fn: function fn(rowData) {
        return [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: rowData.item.counterPartyName,
            expression: "rowData.item.counterPartyName",
            modifiers: {
              "hover": true
            }
          }]
        }, [_vm._v(_vm._s(_vm._f("trimString")(rowData.item.counterPartyName, 35)))])];
      }
    }, {
      key: "cell(saleDate)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.saleDate)))])];
      }
    }, {
      key: "cell(paymentDate)",
      fn: function fn(rowData) {
        return [rowData.item.paymentStatus == 'paid' ? _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.paymentDate)))]) : _c('div', {
          class: ['payment-date', rowData.item.paymentDate && new Date(rowData.item.paymentDate) < new Date() ? 'payment-date--overdue' : '']
        }, [_c('p', {
          staticClass: "payment-date__label"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.paymentDate)))]), _c('span', {
          staticClass: "payment-date__status"
        }, [_vm._v("overdue")])])])];
      }
    }, {
      key: "cell(grossAmount)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table-price-cell"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(rowData.item.grossAmount, 2)))])])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: rowData.item.errorMessage,
            expression: "rowData.item.errorMessage",
            modifiers: {
              "hover": true
            }
          }],
          class: ['outline round', _vm.getDocumentStatusClassName(rowData.item.status)],
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(_vm._s(_vm.statusText(rowData.item.status)))])], 1)];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "td__actions",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [rowData.item.actions.includes('edit') ? _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Edit',
            expression: "'Edit'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('EDIT')
          }
        })]) : _vm._e(), _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Download',
            expression: "'Download'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('DOWNLOAD')
          }
        })]), rowData.item.actions.includes('send-to-approval') ? _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Send for approval',
            expression: "'Send for approval'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('APPROVE')
          }
        })]) : _vm._e(), rowData.item.actions.includes('delete') ? _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Delete',
            expression: "'Delete'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action action--delete"
        }, [_c('svg', {
          attrs: {
            "xmlns": "http://www.w3.org/2000/svg",
            "width": "15.605",
            "height": "18",
            "viewBox": "0 0 15.605 18"
          }
        }, [_c('path', {
          staticStyle: {
            "fill": "currentColor"
          },
          attrs: {
            "d": "M8.188.529a1.8 1.8 0 0 0-1.273.528l-.672.672H3.389a1.807 1.807 0 0 0-1.351.576 1.829 1.829 0 0 0-.453 1.224 1.83 1.83 0 0 0 .453 1.225 1.717 1.717 0 0 0 .749.48v1.895a.6.6 0 1 0 1.2 0V5.33h10.8v11.4a.586.586 0 0 1-.6.6h-9.6a.586.586 0 0 1-.6-.6v-6a.6.6 0 1 0-1.2 0v6a1.809 1.809 0 0 0 1.8 1.8h9.6a1.809 1.809 0 0 0 1.8-1.8V5.235a1.717 1.717 0 0 0 .75-.481 1.83 1.83 0 0 0 .453-1.225 1.829 1.829 0 0 0-.453-1.224 1.807 1.807 0 0 0-1.351-.576h-2.853l-.673-.672a1.8 1.8 0 0 0-1.272-.528zm0 1.2h2.4a.6.6 0 0 1 .424.176l.848.848a.6.6 0 0 0 .424.176h3.1a.533.533 0 0 1 .455.174.685.685 0 0 1 0 .853.533.533 0 0 1-.455.174h-12a.533.533 0 0 1-.455-.174.685.685 0 0 1 0-.853.533.533 0 0 1 .455-.174h3.1a.6.6 0 0 0 .424-.176l.848-.848a.6.6 0 0 1 .432-.176zM6.978 7.72a.6.6 0 0 0-.591.609v6a.6.6 0 1 0 1.2 0v-6a.6.6 0 0 0-.609-.609zm2.4 0a.6.6 0 0 0-.591.609v6a.6.6 0 0 0 1.2 0v-6a.6.6 0 0 0-.609-.609zm2.4 0a.6.6 0 0 0-.591.609v6a.6.6 0 0 0 1.2 0v-6a.6.6 0 0 0-.609-.609z",
            "transform": "translate(-1.585 -.529)"
          }
        })])]) : _vm._e()])];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center text-danger spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }